<template>
    <b-container>
        <b-row style="
        display: flex;
        flex-wrap: wrap;
        margin-right: -9px;
        margin-left: -12px;"
        >
            <b-col lg="12" sm="12">
                <form-input :load="load" :key="reloadComponent" :data="formInputData" @change="setData" @submit="register" />
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import RestApi, { agriMarketingServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { ggProfileStore, ggProfileUpdate, ggProfileDelete } from '../../api/routes'
import FormInput from '@/components/structure/form/validationForm/FormInput'
import { Common, Repository, helpers } from '@/mixins/helper-functions'
import Custom from '../../../../../../store/custom'
import { getCottonMarketUserInfo } from '@/Utils/data-filtering-by-priviledge'

export default {
    props: ['item'],
    mixin: [Common],
    components: {
        FormInput
    },
    created () {
        if (this.item !== null) {
            this.readOnlyData = true
            const tmp = JSON.parse(JSON.stringify(this.item))
            tmp.password = ''
            Object.assign(this.parentObject, tmp)
        }
        // if (false) {
        //   const authOfficeDetail = this.$store.state.Auth.authUser.office_detail
        //   const unit = this.$store.state.agriMarketing.commonObj.unitList.filter(item => item.status === 1).find(obj => obj.district_id === authOfficeDetail.district_id)
        //   if (unit !== undefined) {
        //     this.parentObject.region_id = unit.region_id
        //     this.parentObject.zone_id = unit.zone_id
        //     this.parentObject.unit_id = unit.value
        //     this.parentObject.district_id = unit.district_id
        //     this.parentObject.upazilla_id = unit.upazilla_id
        //   }
        // }
        if (this.isOfficeUser) {
            this.parentObject.type = 2
        }
        this.setUserData()
    },
    data () {
        return {
            isFinal: false,
            parentObject: {
              type: '',
              region_id: '',
              zone_id: '',
              unit_id: '',
              district_id: '',
              upazilla_id: '',
              org_id: '',
              password: '',
              password_confirm: '',
              view_password: false,
              image: null,
              ginner_association_number: ''
            },
            zoneList: [],
            // districtList: [],
            unitList: [],
            orgList: [],
            upazilaList: [],
            passwordErrors: [],
            passwordConfirmErrors: [],
            mobileErrors: [],
            passType: 'password',
            load: false,
            reloadComponent: 1,
            showGrowerData: true,
            showGinnerData: false,
            defaultImage: require('@/assets/images/no-image.png'),
            nameEnLabel: 'globalTrans.name_en',
            nameBnLabel: 'irrigation_config.name_bn',
            fatherNameEnLabel: 'fertilizerConfig.father_name_en',
            fatherNameBnLabel: 'warehouseFarmer.father_name_bn',
            readOnlyData: false
        }
    },
    computed: {
        cottonMarketUserInfo () {
            return getCottonMarketUserInfo()
        },
        regionList () {
            // return this.$store.state.agriMarketing.commonObj.regionList.filter(item => item.status === 1)
            return this.cottonMarketUserInfo.regionList.filter(item => item.status === 1)
        },
        districtList () {
            return this.$store.state.commonObj.districtList.filter(item => item.status === 0)
        },
        // genderList () {
        //     return this.$store.state.commonObj.genderList
        // },
        genderList () {
            const objectData = this.$store.state.commonObj.genderList
            return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
                } else {
                return { value: obj.value, text: obj.text_en }
                }
            })
        },
        isEn () {
          return this.$i18n.locale === 'en'
        },
        formInputData () {
            return {
                chunk: true,
                buttonLocale: this.item ? 'globalTrans.update' : 'globalTrans.save',
                buttonClass: 'btn btn-primary btn-block',
                dataParent: this.parentObject,
                data: [
                    {
                        head: {
                            title: ''
                        },
                        items: [
                            {
                                name: 'type',
                                type: 'Select',
                                label: 'cotton_ginner_grower.selectGrowerGinner',
                                options: helpers.filterListLang(Custom.ginnerGrower),
                                labelCol: 4,
                                readOnly: this.readOnlyData || this.isOfficeUser
                            },
                            {
                                name: 'region_id',
                                type: 'Select',
                                label: 'globalTrans.region',
                                options: this.regionList,
                                labelCol: 4,
                                onIf: this.showGrowerData,
                                readOnly: false
                            },
                            {
                                name: 'zone_id',
                                type: 'Select',
                                label: 'cotton_config.zone_name',
                                options: helpers.filterListLang(this.zoneList),
                                labelCol: 4,
                                onIf: this.showGrowerData,
                                readOnly: false
                            },
                            {
                                name: 'unit_id',
                                type: 'Select',
                                label: 'seedsSeeds.unitName',
                                options: helpers.filterListLang(this.unitList, 'text', 'text_bn', 'value'),
                                labelCol: 4,
                                onIf: this.showGrowerData,
                                readOnly: false
                            },
                            {
                              name: 'ginner_association_number',
                              type: 'Input',
                              label: 'cotton_ginner_grower.ginnerAssociationNumber',
                              labelCol: 4,
                              onIf: this.showGinnerData
                            },
                            {
                                name: 'district_id',
                                type: 'Select',
                                label: 'globalTrans.district',
                                options: this.districtList,
                                labelCol: 4,
                                readOnly: parseInt(this.parentObject.type) === 2
                            },
                            {
                                name: 'upazilla_id',
                                type: 'Select',
                                label: 'globalTrans.upazila',
                                options: this.upazilaList,
                                labelCol: 4,
                                readOnly: parseInt(this.parentObject.type) === 2
                            },
                            {
                                name: 'name',
                                type: 'Input',
                                label: this.nameEnLabel,
                                labelCol: 4
                            },
                            {
                                name: 'name_bn',
                                type: 'Input',
                                label: this.nameBnLabel,
                                labelCol: 4
                            },
                            {
                                name: 'father_name',
                                type: 'Input',
                                label: this.fatherNameEnLabel,
                                labelCol: 4
                            },
                            {
                                name: 'father_name_bn',
                                type: 'Input',
                                label: this.fatherNameBnLabel,
                                labelCol: 4
                            },
                            {
                                name: 'land_area',
                                type: 'Input',
                                inputType: 'text',
                                label: 'cotton_ginner_grower.land_area_acre',
                                labelCol: 4,
                                rules: 'nullable',
                                onIf: this.showGrowerData
                            },
                            {
                                name: 'address',
                                type: 'Input',
                                label: 'irrigation_config.address',
                                labelCol: 4,
                                rules: 'nullable'
                            },
                            {
                                name: 'address_bn',
                                type: 'Input',
                                label: 'irrigation_config.address_bn',
                                labelCol: 4,
                                rules: 'nullable'
                            },
                            {
                                name: 'mobile_no',
                                type: 'Input',
                                errors: this.mobileErrors,
                                label: 'cotton_report.mobile_number',
                                labelCol: 4
                            },
                            {
                                name: 'nid',
                                type: 'Input',
                                label: 'externalUserIrrigation.nid',
                                labelCol: 4,
                                rules: 'nullable'
                            },
                            {
                                name: 'password',
                                type: 'Input',
                                inputType: this.passType,
                                errors: this.passwordErrors,
                                label: 'globalTrans.password',
                                labelCol: 4,
                                rules: this.item ? 'nullable' : 'required'
                            },
                            {
                                name: 'password_confirm',
                                type: 'Input',
                                inputType: this.passType,
                                errors: this.passwordConfirmErrors,
                                label: 'cotton_ginner_grower.repeatPassword',
                                labelCol: 4,
                                rules: this.item ? 'nullable' : 'required'
                            },
                            {
                              name: 'view_password',
                              type: 'CheckboxField',
                              label: 'globalTrans.viewPassword',
                              labelCol: 4,
                              rules: 'nullable'
                            },
                            {
                                name: 'gender',
                                type: 'Select',
                                label: 'globalTrans.gender',
                                options: this.genderList,
                                labelCol: 4,
                                onIf: this.showGrowerData
                            },
                            {
                                name: 'no_of_zinning_unit',
                                type: 'Input',
                                label: 'ginnerGrower.no_of_zinning_unit',
                                labelCol: 4,
                                onIf: this.showGinnerData
                            },
                            {
                              name: 'image',
                              type: 'ImageFile',
                              label: 'globalTrans.image',
                              labelCol: 4,
                              rules: 'nullable',
                              uploadLabel: 'Upload Image',
                              link: this.parentObject.image ? agriMarketingServiceBaseUrl + 'storage/uploads/profile/' + this.parentObject.image : this.defaultImage,
                              note: 'cotton_config.image_file_instruction'
                            }
                        ]
                    }
                ]
            }
        },
        isOfficeUser () {
          return !(this.$store.state.Auth.activeRoleId === 1 || this.$store.state.Auth.authUser.is_org_admin)
        }
    },
    watch: {
        'parentObject.type': function (newVal) {
          if (newVal === 1) {
            this.showGrowerData = false
            this.showGinnerData = true
            this.nameEnLabel = 'cotton_ginner_grower.company_name_en'
            this.nameBnLabel = 'cotton_ginner_grower.company_name_bn'
            this.fatherNameEnLabel = 'cotton_ginner_grower.ginner_name_en'
            this.fatherNameBnLabel = 'cotton_ginner_grower.ginner_name_bn'
          } else {
            this.showGrowerData = true
            this.showGinnerData = false
            this.nameEnLabel = 'globalTrans.name_en'
            this.nameBnLabel = 'irrigation_config.name_bn'
            this.fatherNameEnLabel = 'fertilizerConfig.father_name_en'
            this.fatherNameBnLabel = 'warehouseFarmer.father_name_bn'
          }
        },
        'parentObject.region_id': async function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.orgList = await this.setOrg(newVal)
                this.zoneList = await this.setZone(newVal)
                if (this.orgList.length > 0) {
                    this.parentObject.org_id = this.orgList[0].value
                    this.reloadComponent++
                }
            }
        },
        'parentObject.zone_id': function (newVal, oldVal) {
            if (newVal) {
                // this.districtList = this.getDistrictList(newVal)
                this.unitList = this.setUnit(newVal)
            } else {
                // this.districtList = []
                this.unitList = []
            }
        },
        'parentObject.upazilla_id': function (newVal, oldVal) {
            // if (newVal !== oldVal) {
            //     this.unitList = this.setUnit(newVal)
            // }
        },
        'parentObject.district_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.upazilaList = this.getUpazilaList(newVal)
            }
        },
        'parentObject.unit_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                const obj = this.$store.state.agriMarketing.commonObj.unitList.find(item => item.value === parseInt(newVal))
                this.parentObject.district_id = obj?.district_id
                this.parentObject.upazilla_id = obj?.upazilla_id
            }
        },
        'parentObject.password': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.passwordErrors = []
                if (this.parentObject.password_confirm.length > 0 && this.parentObject.password_confirm !== newVal) {
                    this.passwordErrors.push('Password mismatched!')
                }
            }
        },
        'parentObject.password_confirm': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                if (this.parentObject.password.length < 1) {
                     const msg = this.$t('globalTrans.enterPasswordFirst')
                    this.passwordErrors.push(msg)
                } else {
                    this.passwordErrors = []
                    if (this.parentObject.password.length > 0 && this.parentObject.password !== newVal) {
                        this.passwordErrors.push('Password mismatched!')
                    }
                }
            }
        },
        'parentObject.view_password': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.passType = newVal ? 'text' : 'password'
            }
        },
        cottonMarketUserInfo: function () {
            this.setUserData()
        }
    },
    methods: {
        setZone (regionId) {
            // return this.$store.state.agriMarketing.commonObj.zoneList.filter(el => el.region_id === regionId)
            return this.cottonMarketUserInfo.zoneList.filter(el => el.region_id === regionId)
        },
        setUnit (zoneId) {
            // return this.$store.state.agriMarketing.commonObj.unitList.filter(item => item.status === 1).filter(el => el.zone_id === zoneId)
            return this.cottonMarketUserInfo.unitList.filter(item => item.status === 1 && item.zone_id === zoneId)
        },
        successMsg (result) {
            this.$toast.success({
                title: 'Success',
                message: result.data.id ? 'Data updated successfully' : 'Data saved successfully',
                color: '#D6E09B'
            })
            this.$bvModal.hide('modal-4')
        },
        setOrg (regionId) {
            const region = this.$store.state.agriMarketing.commonObj.regionList.find(el => el.value === regionId)
            if (typeof region !== 'undefined') {
                return this.$store.state.commonObj.organizationProfileList.filter(el => el.value === region.org_id)
            }
        },
        setData (data) {
            this.parentObject = Object.assign(this.parentObject, data)
        },
        getData () {
            return this.$store.state.parentObject
        },
        async register () {
            this.load = true
            try {
                const result = await Repository.create({
                    data: this.parentObject,
                    baseURL: agriMarketingServiceBaseUrl,
                    storeAPI: ggProfileStore,
                    updateAPI: ggProfileUpdate,
                    itemId: this.parentObject.id,
                    showMessage: false
                })

                if (result.success) {
                    const loadinState = { loading: false, listReload: true }
                    this.$store.dispatch('mutateCommonProperties', loadinState)
                    const data = result.data
                    const params = {
                        type: data.type,
                        name: data.name,
                        name_bn: data.name_bn,
                        mobile_no: data.mobile_no,
                        password: data.password,
                        father_name: data.father_name,
                        father_name_bn: data.father_name_bn,
                        nid: data.nid
                    }
                    if (result.type !== 'update') {
                        RestApi.postData(authServiceBaseUrl, '/user/ginner-grower-store', params).then((response) => {
                            if (!response.success) {
                                RestApi.deleteData(agriMarketingServiceBaseUrl, ggProfileDelete + '/' + result.data.id, { mobile_no: result.data.mobile_no }).then(() => {
                                    this.$toast.error({
                                        title: 'Error',
                                        message: 'Something went wrong!'
                                    })
                                })
                            } else {
                                this.successMsg(result)
                            }
                        })
                    } else {
                        this.successMsg(result)
                    }
                    this.$emit('submitted')
                    this.mobileErrors = []
                    this.load = false
                } else {
                    if (result.errors.mobile_no) {
                        this.mobileErrors.push(result.errors.mobile_no[0])
                        this.reloadComponent++

                        this.$toast.error({
                            title: 'Error',
                            message: result.errors.mobile_no[0]
                        })
                    }
                    if (result.errors.password) {
                        this.passwordErrors.push(result.errors.password[0])
                        this.reloadComponent++
                    }
                    if (result.errors.password_confirm) {
                        this.passwordConfirmErrors.push(result.errors.password_confirm[0])
                        this.reloadComponent++
                    }
                    this.load = false
                }
            } catch (e) {
                this.load = false
            }
        },
        getUpazilaList (districtId = null) {
            // const upazilaList = this.$store.state.commonObj.upazilaList
            const upazilaList = this.cottonMarketUserInfo.upazilaList.filter(item => item.status === 0)

            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }
            return upazilaList
        },
        getDistrictList (id) {
            // return this.$store.state.commonObj.districtList.filter(item => item.status === 0)
            const obj = this.cottonMarketUserInfo.zoneList.find(item => item.value === parseInt(id))
            const districtIdArr = obj.district_ids ? JSON.parse(obj.district_ids) : []
            return this.cottonMarketUserInfo.districtList.filter(item => districtIdArr.includes(parseInt(item.value)))
        },
        setUserData () {
            if (this.cottonMarketUserInfo.regionList.length === 1) {
                this.parentObject.region_id = this.cottonMarketUserInfo.regionList[0].value
            }
            if (this.cottonMarketUserInfo.zoneList.length === 1) {
                this.parentObject.zone_id = this.cottonMarketUserInfo.zoneList[0].value
            }
            if (this.cottonMarketUserInfo.districtList.length === 1) {
                this.parentObject.district_id = this.cottonMarketUserInfo.districtList[0].value
            }
            if (this.cottonMarketUserInfo.upazilaList.length === 1) {
                this.parentObject.upazilla_id = this.cottonMarketUserInfo.upazilaList[0].value
            }
            if (this.cottonMarketUserInfo.unitList.length === 1) {
                this.parentObject.unit_id = this.cottonMarketUserInfo.unitList[0].value
            }
        }
    }
}
</script>
